
<template>
<div>
    <header>
      <slot name="header">
        Default header content
      </slot>
    </header>
    <h1>I am custom Layout</h1>
    <slot />
    <AppFooter />
</div>
</template>
